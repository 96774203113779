@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

:root {
  font-family: Montserrat, system-ui, sans-serif;
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Montserrat, Monaco, "Cascadia Mono",
    "Segoe UI Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  /* Current default theme is set to be light */
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 255, 255, 255;
  --background-end-rgb: 255, 255, 255;

  /* Dark theme setting (default) */
  /* --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255; */

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@font-face {
  font-family: "Neutra";
  src: url("/fonts/Neutra Fonts-selected/NeutraTextTF-Book.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neutra-title";
  src: url("/fonts/Neutra Fonts-selected/Neutra Text SC.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/static/Montserrat-Medium.ttf");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Semibold";
  src: url("/fonts/montserrat/static/Montserrat-SemiBold.ttf");
  font-weight: 700;
  font-style: normal;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 255, 255, 255;
    --background-end-rgb: 255, 255, 255;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

p {
  font-size: 30;
}

h1 {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

h2 {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

h3 {
  padding-top: 2rem;
}

h5 {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

ul {
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

